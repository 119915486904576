import React from "react";
import "../styles/PageContent.scss";

class PageContent extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <main className="page-content">
        <div className="page-content__centered">
          {children}
        </div>
      </main>
    )
  }
}

export default PageContent
