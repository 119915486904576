import React from "react";
import "../styles/PageHero.scss";

class PageHero extends React.Component {
  render() {
    const { title, children } = this.props;

    return (
      <section className="page-hero">
        <h1 className="page-hero__title">
          {title}
        </h1>
        {children}
      </section>
    )
  }
}

export default PageHero
