import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import PageHero from "../components/PageHero";
import PageContent from "../components/PageContent";
import SightlinesSignup from "../components/SightlinesSignup";
import GeometricBackground from "../../content/assets/images/geometric-background.svg";
import "../styles/pages/products.scss";

class Products extends React.Component {
  render() {
    const pageName = "products";

    return (
      <Layout location={this.props.location} pageName={pageName}>
        <SEO
          title="Products"
          keywords={[`software development`, `website development`, `mobile application development`, `SaaS consulting`, `review apps`, `software consulting`, `quality assurance`]}
        />
        <PageHero title="Sightlines">
          <div className="page-hero__geometric-background" style={{backgroundImage: `url(${GeometricBackground})`}} />
          <h2 className="page-hero__subtitle">Review environments for web applications</h2>
          <SightlinesSignup inBox={false} />
        </PageHero>
        <PageContent>
          <p>
            Our customers are searching for something that lets them deliver the software that they are trying to build quickly on a fixed budget. We want them to be able to bring their product vision to market quicker and on-budget.
          </p>
          <p>
            Non-technical business leaders do not have insight into the software development process until changes are completed. Sometimes these changes are not what the business leaders had envisioned, but they incurred the full cost of developing and re-developing their solution. Software development is extremely expensive. Providing early feedback in the development process can help your team course correct sooner saving you money and preventing costly delays.
          </p>
          <p>
            Sightlines is a quick and easy-to-setup solution that creates safe and secure internal websites where non-technical business leaders can view the work in progress and provide their team feedback earlier than ever before. This allows you to see the changes in your browser and send your team early feedback keeping them on-track and preventing costly delays.
          </p>
          <SightlinesSignup inBox={true} />
        </PageContent>
      </Layout>
    )
  }
}

export default Products;
