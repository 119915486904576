import React, { Component } from "react";
import Mailchimp from "react-mailchimp-form";
import "../styles/SightlinesSignup.scss";

class SightlinesSignup extends Component {
  render() {
    return (
      <div className={this.props.inBox ? `sightlines-signup sightlines-signup--in-box` : `sightlines-signup`}>
        {this.props.inBox &&
          <div>
            <h3 className="sightlines-signup__title">Sound interesting?</h3>
            <p className="sightlines-signup__cta">
              Enter your email address below and we'll keep in touch as we get closer to launch.
            </p>
          </div>
        }
        <Mailchimp
          action="https://services.us20.list-manage.com/subscribe/post?u=7f4af49ca38c0d62d6dbc956a&amp;id=2637e094ee"
          fields = {[
            {
              name: "EMAIL",
              placeholder: "Email address",
              type: "email",
              required: true
            }
          ]}
          messages = {
            {
              sending: "Sending...",
              success: "Thank you for your interest! We'll be in touch soon.",
              error: "An unexpected internal error has occurred.",
              empty: "You must enter an email address above.",
              duplicate: "Too many subscribe attempts for this email address",
              button: "Subscribe for updates"
            }
          }
          className = "sightlines-signup__form"
        />
      </div>
    );
  }
}

export default SightlinesSignup;
